<template>
    <section>
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Grupo de permisos" class="mx-0">
                    <div class="row">
                        <!-- <el-tooltip content="Añadir grupo de permisos" effect="light" placement="top" visible-arrow>
                            <div class="col-auto icon-option my-auto px-1" @click="createNewGrupoPermiso">
                                <i class="icon-plus-circle f-30 cr-pointer"/>
                            </div>
                        </el-tooltip> -->
                        <tabs :tabs="tabsConfig" :inline="true" />
                    </div>
                </titulo-divisor>
            </div>
        </div>  
        <tabla-permisos :heads='cabeceras' :rutasActive='rutasActive'/>
        <!-- partials -->
        <!-- <modal-crear-grupo-permiso ref="createGrupoPermiso"/> -->
    </section>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex';
import tablaPermisos from '../components/table.vue'
export default {
    components:{
        tablaPermisos,
        modalCrearGrupoPermiso: () => import('./../partials/modalCrearGrupoPermiso.vue'),        
    },
    data(){
        return {
            tabsConfig: [],
            cabeceras: [], 
            rutasActive: false,
            // btnAdd: {
            //     isVisible: true,
            //     title: "Añadir Permiso",
            //     type: 2 //accion
            // },
            payload: {
                id_menu: null,
                tipo: 2
            }
        }
    },
    computed: {
        ...mapGetters({
            menu: 'configurar/permisos/menus/menus',
            header: 'configurar/permisos/menus/headers',
        }),
        menus: {
           get(){
             return this.menu
           },
           set(newName){
             return newName
           } 
        },
        headers: {
           get(){
             return this.header
           },
           set(newName){
             return newName
           } 
        },
        id(){ 
            return this.$route.params.id
        },
        tab(){ 
            return this.$route.params.tab
        }
    },
    watch:{
        id(){
            this.getSubMenus()
        },
        tab(){
            this.payload.id_menu = this.tab;    
            this.handleGetHeaders()
        }
    },  
    async created(){
        await this.getMenus()
        this.getSubMenus()
        await this.handleGetHeaders()
    }, 
    methods:{
        ...mapActions({
            getMenus: 'configurar/permisos/menus/Action_get_menus',
            getHeaders: 'configurar/permisos/menus/Action_get_headers',
        }),
        createNewGrupoPermiso(){
            this.$refs.createGrupoPermiso.toggle()
            this.handleGetHeaders()
        },
        getSubMenus() {
            this.payload.id_menu = this.tab;
            this.tabsConfig = [];
            this.tabsConfig = this.menus.filter(el => el.id_menu_superior == this.id).map(element =>{ 
                return {
                    titulo: element.nombre,
                    ruta: 'configurar.permisos.acciones.menu.grupo',
                    params: { tab: element.id }
                }
            })
        },
        async handleGetHeaders(){
            await this.getHeaders(this.payload)
            this.createHeaders()
        },
        async createHeaders() { 
            this.cabeceras = this.headers.map(element =>{ 
                return {
                    nombre: element.nombre,
                    ruta: element.name_ruta,
                    id: element.id
                }
            })
        },
    }
}
</script>
